<!--
 * @Author: 张博洋
 * @Date: 2022-02-28 14:02:33
 * @LastEditTime: 2022-03-09 01:10:57
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/components/archives/detail.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
    <div class="wrap full-h" >
        <van-cell-group inset v-if="$route.name === 'patient.detail'">
            <h1  v-if="$route.name !== 'patient.detail'">既往病史</h1>
            <van-cell title="既往病史" :value="detail.disease.ownIllHistory === 1 ? detail.disease.ownIllHistoryText : '无'" />
            <van-cell title="过敏史" :value="detail.disease.allergyHistory === 1 ? detail.disease.allergyHistoryText : '无'" />
            <van-cell title="家族遗传史" :value="detail.disease.familyHistory === 1 ? detail.disease.familyHistoryText : '无'" />
            <van-cell title="肝功能异常" :value="detail.disease.liverFunction === 1 ? detail.disease.liverFunctionText : '无'" />
            <van-cell title="肾功能异常" :value="detail.disease.kidneyFunction === 1 ? detail.disease.kidneyFunctionText : '无'" />
        </van-cell-group>

        <van-cell-group inset>
            <h1>基础数据</h1>
            <van-cell title="身高" :value="`${detail.height} CM`" />
            <van-cell title="体重" :value="`${detail.weight} KG`" />
            <van-cell title="体质指数" :value="detail.bmi" />
        </van-cell-group>

        <van-cell-group inset v-if="$route.name !== 'patient.detail'">
            <h1>既往病史</h1>
            <van-cell title="既往病史" :value="detail.disease.ownIllHistory === 1 ? detail.disease.ownIllHistoryText : '无'" />
            <van-cell title="过敏史" :value="detail.disease.allergyHistory === 1 ? detail.disease.allergyHistoryText : '无'" />
            <van-cell title="家族遗传史" :value="detail.disease.familyHistory === 1 ? detail.disease.familyHistoryText : '无'" />
            <van-cell title="肝功能异常" :value="detail.disease.liverFunction === 1 ? detail.disease.liverFunctionText : '无'" />
            <van-cell title="肾功能异常" :value="detail.disease.kidneyFunction === 1 ? detail.disease.kidneyFunctionText : '无'" />
        </van-cell-group>

        <van-cell-group inset >
            <h1>确诊疾病</h1>
            <div class="diagnoses" v-for="(item,index) in detail.diagnoses" :key="index">
                <van-cell :title="`确诊日期${index + 1}`" :value="item.diagnoseDate" />
                <van-cell :title="`临床诊断${index + 1}`" :value="item.diagnose" />
            </div>
        </van-cell-group>

         <van-cell-group inset >
            <h1>生活方式</h1>
            <van-cell title="锻炼方式" :value="detail.exerciseWay" />
            <van-cell title="锻炼强度" :value="detail.exerciseStrength" />
            <van-cell title="锻炼频次" :value="detail.exerciseFrequency" />
            <van-cell title="每次锻炼时间" :value="`${detail.exerciseTime} 分钟`" />
            <van-cell title="睡眠时长" :value="`${detail.sleepTime} 小时/每天`" />
            <van-cell title="睡眠状况" :value="detail.sleepState" />
            <van-cell title="饮食习惯" :value="detail.dietHabit" />
        </van-cell-group>

        
    </div>
</template>

<script>
export default {
    props:['archivesData'],
    watch:{
        archivesData:{
            handler(val) {
                console.log(val)
                this.detail = val
            },
            deep:true,
            immediate:true
        }
    },
    data(){
        return {
            detail:{
                bmi:'',
                diagnoses: '',
                dietHabit: '',
                disease:{
                   
                },
                exerciseFrequency: '',
                exerciseStrength: '',
                exerciseTime: '',
                exerciseWay: '',
                height: '',
                sleepState: '',
                sleepTime: '',
                weight: '',
            }
        }
    },
    mounted(){
       
    },
    methods:{
       
    }
}
</script>   

<style lang="scss" scoped>
.wrap {

}
/deep/.van-cell-group {
    margin: 8px;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding: 12px 16px;
      em {
        color: #da5756;
        margin-right: 8px;
      }
    }
    .van-cell::after {
      border-color: #e5e5e5;
    }
    .van-cell {
        .van-cell__title {
            color: #666;
            flex: none;
             width: 112px;
            flex: none;
        }
        .van-cell__value {
            word-wrap: unset;
            color: #333;
            flex: 1;
            text-align: left;
        }
    }
    .diagnoses {
        border-radius:8px;
        border: 1px solid #E5E5E5;
        margin: 8px;
        overflow: hidden;
    }
  }
</style>