<template>
    <div>
        <archives :archivesData="archivesData"></archives>
    </div>
    
</template>

<script>
import archives from '@/components/archives/detail.vue'
export default {
    data(){
        return {
            archivesData:{}
        }
    },
    created(){
        this.getDoc()
    },
    components:{
        archives
    },
    methods:{
       getDoc() {
            this.$axios({
                url: 'healthyDoc/queryHealthDoc',
                data: {
                patientId: this.$route.query.id,
                },
                elseData: {
                loading: true,
                },
            }).then((res) => {
                console.log(res)
                this.archivesData = res.d
            })
        },
       }
}
</script>

<style lang="scss" scoped>
.wrap {
    height: 100%;
    overflow-y: auto;
}
</style>